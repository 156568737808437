import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// components
import { Navbar, Footer, BackgroundSectionGeneric, Banner } from 'src/components/common';
import { SectionHeader } from 'src/components/Photography';

const listings = [
  {
    name: 'ladlink intl sch',
  },

  {
    name: 'very ghanaian',
  },

  {
    name: 'watch hub',
  },
  {
    name: 'star brewery',
    path: 'star-gh',
  },
];

const CommercialsPage = (props) => {
  return (
    <>
      <Navbar dark />

      <StyledSubSectionLayout
        className="container"
        style={{
          marginTop: '100px',
        }}
      >
        <SectionHeader sectionName="Ads" />

        <div className="listing">
          {listings.map((item, i) => {
            const path = item.path ? item.path : item.name.toLowerCase().split(' ').join('-');

            return (
              <BackgroundSectionGeneric
                name={path}
                className={`${i === listings.length - 1 && 'list-middle-item'} list-item `}
              >
                <Link to={`${props.path}${path}`}>
                  <div key={i}>
                    <h2>{item.name}</h2>
                  </div>
                </Link>
              </BackgroundSectionGeneric>
            );
          })}
        </div>
      </StyledSubSectionLayout>

      <Banner />
      <Footer />
    </>
  );
};

const StyledSubSectionLayout = styled.section`
  min-height: 100vh;

  .listing {
    margin-bottom: 200px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-template-rows: repeat(2, auto);
    grid-gap: 10px;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
    }

    .list-middle-item {
      grid-column-start: span 3;
    }

    .list-item {
      height: 530px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: contain;
      box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);

      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 400px;
      }

      :hover {
        filter: brightness(87.5%);
      }

      h2 {
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        font-size: 2rem;
        padding: 0 20px;
        background: rgba(0, 0, 0, 1);
        transform: translateZ(100px);
      }
    }
  }
`;

export default CommercialsPage;
